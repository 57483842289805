import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { APP_NAME, WEB_SITE_URL } from '../../../constants';

const CopyRightWidget = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" >
      {'Copyright © '}
      <Link color="inherit" href={WEB_SITE_URL} target='_blank'>
        {APP_NAME}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default CopyRightWidget;
