import {combineReducers} from 'redux';

//Reducers Imports
import settings from './settings/reducer';
import auth from './auth/reducer';
import clients from './clients/reducer';
import admin from './admin/reducer';
//End of Reducers Imports

export default combineReducers({
    settings: settings,
    auth: auth,
    clients: clients,
    admin: admin
});