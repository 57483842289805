import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import MyListPicker from "../global/listPicker/MyListPicker";
import { toast } from "react-toastify";
import slugify from 'slugify'; // Importing slugify

const AddEditClient = (props) => {
  const { editItem } = props;
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [appId, setAppId] = useState("");
  const [appType, setAppType] = useState("FOOD");
  const [logoImg, setLogoImg] = useState("");
  const [logoImgThumb, setLogoImgThumb] = useState("");
  const [status, setStatus] = useState(true);
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [isAppIdManuallyChanged, setIsAppIdManuallyChanged] = useState(false);

  useEffect(() => {
    var applicationTypesOptions = [];
    settingsData.app_types_list.forEach((element) => {
      applicationTypesOptions.push({
        value: element.app_type_value,
        text: element.app_type_text,
      });
    });
    setApplicationTypes(applicationTypesOptions);
  }, [settingsData]);

  useEffect(() => {
    if (props.toGetData) {
      let postData = makePostItem();

      if (editItem != null) {
        postData.id = editItem.id;
      }

      props.onSubmitData(postData);
    }
  }, [props.toGetData]);

  const makePostItem = () => {
    return {
      name,
      desc,
      app_id: appId,
      app_type: appType,
      logo_img: logoImg,
      logo_img_thumb: logoImgThumb,
      status: { active: status },
    };
  };

  useEffect(() => {
    if (editItem != null) {
      setName(editItem.name);
      setDesc(editItem.desc);
      setAppId(editItem.app_id);
      setAppType(editItem.app_type);
      setLogoImg(editItem.logo_img);
      setLogoImgThumb(editItem.logo_img_thumb);
      setStatus(editItem.status.value);
      setIsAppIdManuallyChanged(true);
    } else {
      clearData();
    }
  }, [editItem]);

  useEffect(() => {
    if (props.toClearData) {
      clearData();
    }
  }, [props.toClearData]);

  const clearData = () => {
    setName("");
    setDesc("");
    setAppId("");
    setAppType("FOOD");
    setLogoImg("");
    setLogoImgThumb("");
    setStatus(true);
    setIsAppIdManuallyChanged(false);
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    if (!isAppIdManuallyChanged && !editItem) {
      setAppId(slugify(newName, { lower: true }));
    }
  };

  // UI
  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <hr />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={trans.app_name}
            value={name}
            onChange={handleNameChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={trans.app_description}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={trans.app_id}
            value={appId}
            onChange={(e) => setAppId(e.target.value)}
            fullWidth
            disabled={!!editItem}
          />
        </Grid>

        <Grid item s={12} xs={12} md={12}>
          <MyListPicker
            labelId={"app_type"}
            label={trans.app_type_field}
            selected={appType}
            onChange={(value) => setAppType(value)}
            list={applicationTypes}
          />
        </Grid>

        <Grid item xs={12}>
          <MyStatusPicker
            label={trans.status}
            status={status}
            onChange={(value) => setStatus(value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditClient;
