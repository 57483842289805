import React, { useState, useEffect } from 'react';
import './dashboard.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from "react-redux";
import Paper from '@mui/material/Paper';
import CustomTitle from '../global/CustomTitle';
import { postDashboardMain } from '../../redux/admin/action';
import { getEuroSymbol } from '../../utils/orders/OrdersUtils';


const Dashboard = () => {
  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  const userInfoData = useSelector(({ auth }) => auth.userInfoData);

  const [dashboardData, setDashboardData] = useState(null);

  const trans = settingsData.trans;

  const dashboardMain = useSelector(({ admin }) => admin.dashboardMain);

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    if (dashboardMain && dashboardMain.stats) {
      setDashboardData(dashboardMain);
    }
  }, [dashboardMain]);

  const getDashboardData = () => {
    dispatch(postDashboardMain({}));
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      {dashboardData != null ?
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.dashboard_welcome}, ${userInfoData.userInfo.fullname}`}</CustomTitle>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.clients_count}`}</CustomTitle>
              <h2>{dashboardData.stats.clientsCount}</h2>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.active_clients_count}`}</CustomTitle>
              <h2>{dashboardData.stats.activeClientsCount}</h2>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.inactive_clients_count}`}</CustomTitle>
              <h2>{dashboardData.stats.inActiveClientsCount}</h2>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.today_clients_count}`}</CustomTitle>
              <h2>{dashboardData.stats.clientsCreatedToday}</h2>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.week_clients_count}`}</CustomTitle>
              <h2>{dashboardData.stats.clientsCreatedThisWeek}</h2>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.month_clients_count}`}</CustomTitle>
              <h2>{dashboardData.stats.clientsCreatedThisMonth}</h2>
            </Paper>
          </Grid>

        </Grid> : null}
    </Container>
  );
}

export default Dashboard;
