import {
  POST_SIGN_IN,
  POST_SIGN_OUT,
  POST_USER_INFO,
  
} from "../actionTypes";

const INIT_STATE = {
  signInData: null,
  logoutData: null,
  userInfoData: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_SIGN_IN:
      return {
        ...state,
        signInData: action.payload,
      };
    case POST_SIGN_OUT:
      return {
        ...state,
        logoutData: action.payload,
      };
    case POST_USER_INFO:
      return {
        ...state,
        userInfoData: action.payload,
      };
  
    default:
      return state;
  }
};

export default reducer;
