import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MyStatusPicker = (props) => {
  
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const trans = settingsData.trans;

  return(<div >
      <FormControl fullWidth>
                  <InputLabel id="visible_state">{props.label}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={props.status}
                          label={props.label}
                          onChange={(e) => props.onChange(e.target.value)}>
                          <MenuItem key={`status_item_true`} value={true}>{trans.item_status_active}</MenuItem>
                          <MenuItem key={`status_item_false`} value={false}>{trans.item_status_inactive}</MenuItem>
                  </Select>
              </FormControl>
  </div>);
};

export default MyStatusPicker;
