import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { APP_NAME, WEB_SITE_URL } from '../../../constants';
import CustomTitle from '../CustomTitle';

const PageTitleDesc = (props) => {
  return (<div>
         <div className='_flex_row_space'>
          <CustomTitle>{props.title}</CustomTitle>
        </div>
        <span className='fontRegular' style={{fontSize:'14px'}}>{props.desc}</span>

  </div>);
};

export default PageTitleDesc;
