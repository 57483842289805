import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
// import { initializeFirebase } from './firebase/FirebaseInit';
// import registerServiceWorker from './firebase/registerServiceWorker';
import {createStore, applyMiddleware, compose} from 'redux';
import reduxThunk from 'redux-thunk';

import MainApp from './components/mainApp';
import reducers from './redux';
import * as Const from './constants';




const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);


ReactDOM.render(
  <Provider store={store}>
    <MainApp />
  </Provider>,
  document.querySelector('#root')
);



