import {
  GET_SETTINGS,
  GET_COUNTRIES,
  ADD_EDIT_INIT_DATA,
  BULK_EDIT_INIT_DATA
} from '../actionTypes';
  
const INIT_STATE = {
  settingsData: null,
  countriesData: null,
  addEditInitDataResponse: null,
  bulkEditInitDataResponse: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case GET_SETTINGS:
                   return {
                     ...state,
                     settingsData: action.payload
                }
          case GET_COUNTRIES:
                  return {
                    ...state,
                    countriesData: action.payload
               }
          case ADD_EDIT_INIT_DATA: 
               return {
                ...state,
                addEditInitDataResponse: action.payload
               }
        case BULK_EDIT_INIT_DATA: 
               return {
                ...state,
                bulkEditInitDataResponse: action.payload
            }   
        default:
              return state;
        }
};
      
export default reducer;