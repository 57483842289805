import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MyListPicker = (props) => {

  return(<div >
      <FormControl fullWidth>
                  <InputLabel id={props.list_id}>{props.label}</InputLabel>
                  <Select
                          labelId={props.list_id}
                          id={props.list_id}
                          value={props.selected}
                          label={props.label}
                          onChange={(e) => props.onChange(e.target.value)}>
                            {props.list.map((listItem) => {
                              return (<MenuItem key={`list_item_${listItem.value}`} value={listItem.value}>{listItem.text}</MenuItem>)
                            })}
                  </Select>
              </FormControl>
  </div>);
};

export default MyListPicker;
