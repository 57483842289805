import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Grid, Paper, TablePagination, TableSortLabel } from "@mui/material";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";
import { getStatusText } from "../../utils/tables/tableUtils";
import AddEditClient from "./AddEditClient";
import FormDialog from "../global/formDialog";
import { toast } from "react-toastify";
import { POST_CLIENTS_ADD, POST_CLIENTS_ALL } from "../../redux/actionTypes";
import { postAppClientAdd, postAppClientsAll } from "../../redux/clients/action";

const ClientsList = () => {
  // Selectors
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const clientsData = useSelector(({ clients }) => clients.clientsData);
  const addClientsData = useSelector(({ clients }) => clients.addClientsData);

  // Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  // Hooks
  const [clientList, setClientsList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [toGetData, setToGetData] = useState(false);
  const [toClearData, setToClearData] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    reloadData();
  }, [order, orderBy, page, rowsPerPage, searchQuery]);

  useEffect(() => {
    if (clientsData) {
      setClientsList(clientsData.app_clients);
    }
  }, [clientsData]);

  const getData = () => {
    const params = {
      page: page + 1,
      limit: rowsPerPage,
      sortField: orderBy,
      sortOrder: order === 'asc' ? 1 : -1,
      searchQuery
    };
    dispatch(postAppClientsAll(params));
  };

  const reloadData = () => {
    getData();
  };

  useEffect(() => {
    // ADD DATA
    if (addClientsData != null) {
      if (addClientsData.status) {
        toast.dismiss();
        toast.success(addClientsData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addClientsData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: POST_CLIENTS_ADD, payload: null });
    }
  }, [addClientsData]);

  const handleEdit = (e, item) => {
    e.preventDefault();
    setToClearData(false);
    setEditItem(item);
    setAddModal(true);
  };

  const onAddNew = (e) => {
    e.preventDefault();
    // OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  };

  const onDialogClose = () => {
    setAddModal(false);
  };

  const onDialogSubmit = () => {
    setToGetData(true);
  };

  const onSubmitData = (data) => {
    if (data != null) {
      let postData = data;
      setEditItem(null);
      dispatch(postAppClientAdd(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      setToGetData(false);
    }
  };

  const handleAddStore = (e) => {
    e.preventDefault();
    setEditItem(null);
    setAddModal(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <PageTitleDesc
                title={settingsData.trans.menu_clients}
                desc={settingsData.trans.menu_clients_desc}
              />
            </div>
           
            <Button
              variant="contained"
              onClick={handleAddStore}
              sx={{ mt: { xs: 2, md: 0 } }} // Conditional top margin
            >
              {trans.add_side_menu_button}
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12}>
        <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
 <TextField
              label={trans.search_field}
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              sx={{ mt: { xs: 2, md: 0 }, mr: 2 }} // Conditional top margin
            />

          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'app_id'}
                        direction={orderBy === 'app_id' ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, 'app_id')}
                      >
                        {trans.table_head_side_menu_app_id}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, 'name')}
                      >
                        {trans.table_head_side_menu_name}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'status'}
                        direction={orderBy === 'status' ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, 'status')}
                      >
                        {trans.table_head_status}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'app_type'}
                        direction={orderBy === 'app_type' ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, 'app_type')}
                      >
                        {trans.table_head_page_type}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'created_at'}
                        direction={orderBy === 'created_at' ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, 'created_at')}
                      >
                        {trans.table_head_created_at}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>{trans.table_head_actions}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientList.map((client) => (
                    <TableRow key={client.id}>
                      <TableCell className="fontBold">
                        {client.app_id}
                      </TableCell>
                      <TableCell className="fontBold">
                        {client.name}
                      </TableCell>
                      <TableCell>
                        {getStatusText(client.status.value, trans)}
                      </TableCell>
                      <TableCell>
                        {client.app_type}
                      </TableCell>
                      <TableCell>
                        {new Date(client.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <div className="flex_row">
                          <Button
                            variant="contained"
                            onClick={(e) => handleEdit(e, client)}
                          >
                            {trans.table_row_edit_button}
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={clientsData ? clientsData.total : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${
          editItem == null ? trans.add_client_title : trans.edit_client_title
        }`}
        body={
          <AddEditClient
            toGetData={toGetData}
            toClearData={toClearData}
            onSubmitData={onSubmitData}
            reloadData={() => {
              reloadData();
            }}
            editItem={editItem}
          />
        }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />
    </Container>
  );
};

export default ClientsList;
